import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import NavbarLogo from './logo'
import NavbarList from './list'
// import Search from "../Search"

import { Container } from "react-bootstrap";
import PropTypes from "prop-types";


const SiteNavbar = ({logo}) => {

  let navBrand;

  if (logo) {
    navBrand = (
      <Navbar.Brand href="/" id="site-navbar-brand">
        <NavbarLogo />
      </Navbar.Brand>
    )
  }

  return (
    <Navbar sticky="top" variant="light" bg="white" expand="md" id="site-navbar" className="navigation-clean">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          { navBrand }
          <NavbarList />
          {/*<Search collapse indices={searchIndices} />*/}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

SiteNavbar.propTypes = {
  logo: PropTypes.bool,
}

SiteNavbar.defaultProps = {
  logo: true,
}

export default SiteNavbar