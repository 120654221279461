import React from "react"
import Image from "react-bootstrap/Image";

const Logo = () => {
  return (
    <Image
      className={'d-inline'}
      alt='lbd-logo-purple-verical'
      src='https://assets.lbdscouts.org.uk/wp-content/uploads/2020/07/12133911/LBA-Purple-Vertical.png'
      width={50}
      height={50}
    />
  )
}

export default Logo

