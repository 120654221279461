import React from "react"
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import UsefulLinksFooter from "./useful-links-footer";
import Copywrite from "./copywrite";


const Footer = () => {
  return (
    <footer className="page-footer">
      <Container>
        <Row className="justify-content-center">
          <Col md={3} sm={4}>
            <h3>Our District</h3>
            <ul>
              <li><Link to={'/news'}>Latest news</Link></li>
              <li><Link to={'/events'}>Up Coming events</Link></li>
              <li>Photo Gallery</li>
            </ul>
          </Col>

          <UsefulLinksFooter />

          <Col md={3} sm={4}>
            <h3>Joining in</h3>
            <ul>
              <li>Join the Adventure</li>
              <li>Adult Volunteers</li>
              <li>Compass</li>
            </ul>
          </Col>

          <Copywrite />
        </Row>
      </Container>
    </footer>
  )
}

export default Footer