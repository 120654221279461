import React from "react"
import { Col } from "react-bootstrap";


const Copywrite = () => {
  return (
    <Col lg={3} className={'item social'} >
      <p className="copyright">Copyright© Letchworth and Baldock District Scouts 2020</p>
    </Col>
  )
}

export default Copywrite