import React from "react"
import Nav from 'react-bootstrap/Nav'
import { graphql, useStaticQuery } from "gatsby";


export default props  => {
  const menuItems = useStaticQuery(graphql`
  query menuItems($eq: String = "nav-logged-out") {
    allWpMenuItem(filter: {parentId: {eq: null}, menu: {node: {slug: {eq: $eq}}}}) {
      nodes {
        path
        label
        databaseId
        id
        order
      }
    }
  }`);
  const menu = menuItems.allWpMenuItem.nodes

  return (
      <Nav className="ml-auto">
        {menu.map((nodes) => {
          return (
            <Nav.Link
              key={ nodes.order.toString() + nodes.id }
              href={ nodes.path }
            >{ nodes.label }</Nav.Link>
          )
        })}
      </Nav>
  )
}

