import React from "react"
import { Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";


const UsefulLinksFooter = () => {
  const footerQuery = useStaticQuery(graphql`
    query FooterQuery($eq: String = "footer-links") {
      allWpMenuItem(filter: {menu: {node: {slug: {eq: $eq}}}}) {
        nodes {
          order
          id
          label
          path
        }
      }
    }
  `);
  const menu = footerQuery.allWpMenuItem.nodes

  return (
    <Col md={3} sm={4}>
      <h3>Useful Links</h3>
      <ul>
        { menu.map((nodes) => {
          return (
            <li key={ nodes.order.toString() + nodes.id }>
              <a href={ nodes.path }>{ nodes.label }</a>
            </li>
          )
        })}
      </ul>
    </Col>
  )
}

export default UsefulLinksFooter